<template>
  <div class="ui-map" :style="{ height: height }">
    <l-map
      ref="lMapRef"
      v-model="filter.Zoom"
      v-model:zoom="filter.Zoom"
      :center="c"
      @update:bounds="updateBounds"
      @update:zoom="updateZoom"
      @click="onClick($event)"
    >
      <l-tile-layer
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
      ></l-tile-layer>

      <l-marker
        v-if="marker"
        :lat-lng="marker"
        :draggable="true"
        @dragend="onDragen($event)"
      >
        <l-icon
          :icon-url="require('@assets/img/map-marker.svg')"
          :icon-size="[iconWidth, iconHeight]"
        >
        </l-icon>
      </l-marker>

      <l-feature-group ref="lFeatureGroup" v-if="markers && markers.length">
        <div>
          <l-marker
            v-for="(point, index) in markers"
            class="marker-icon"
            :lat-lng="{ lat: point.lat, lng: point.lng }"
            :draggable="false"
            :key="index"
            @click="onClickMarker(point)"
          >
            <l-icon v-if="getClusterNumber(point) == 1">
              <img
                class="l-map-point-img"
                :src="require('@assets/img/map-marker.svg')"
                width="25"
                height="40"
              />
            </l-icon>
            <l-icon
              v-if="getClusterNumber(point) > 1 && getClusterNumber(point) < 10"
            >
              <img
                class="l-map-point-img"
                :src="require('@assets/img/map-claster1.svg')"
                width="25"
                height="40"
              />
              <div
                class="l-map-points-count"
                style="left: -2px; bottom: 9px"
                v-if="getClusterNumber(point) > 1"
              >
                {{ getClusterNumber(point) }}
              </div>
            </l-icon>
            <l-icon v-if="getClusterNumber(point) > 9">
              <img
                class="l-map-point-img"
                :src="require('@assets/img/map-claster1.svg')"
                width="50"
                height="80"
              />
              <div
                class="l-map-points-count"
                style="left: 18px; bottom: 29px; font-size: 18px"
                v-if="getClusterNumber(point) > 1"
              >
                {{ getClusterNumber(point) }}
              </div>
            </l-icon>
            <l-popup>
              <slot name="marker-popup" v-bind:marker="{ point, index }" />
            </l-popup>
          </l-marker>
        </div>
        <l-polyline
          v-if="isPolyline"
          :weight="5"
          lineCap="square"
          :lat-lngs="polyline"
          color="#5D58B7"
          dashArray="7,10"
          dashOffset="0"
        ></l-polyline>
        //template
      </l-feature-group>
    </l-map>

    <ui-loader :is-loader="isLoader" />
  </div>
</template>
<!-- @format -->

<script lang="ts" setup>
import { computed, onMounted, PropType, ref, watch } from "vue";
import { MapFilterModel, MarkerMapModel } from "./models";
import "leaflet/dist/leaflet.css";
import {
  LMap,
  LTileLayer,
  LIcon,
  LMarker,
  LPolyline,
  LFeatureGroup,
  LPopup,
} from "@vue-leaflet/vue-leaflet";
import { GeoSearchControl, OpenStreetMapProvider } from "leaflet-geosearch";
import { log } from "console";
import { center } from "@turf/turf";

const props = defineProps({
  /**
   * * Центральные координаты
   */
  center: {
    type: Object as PropType<Array<String>>,
    default: ["46.84997809109709", "29.60698474364398"],
  },
  /**
   * * Координаты маркера
   */
  marker: {
    type: Object as PropType<MarkerMapModel>,
  },
  /**
   * * Координаты маркереров
   */
  markers: {
    type: Object as PropType<Array<MarkerMapModel>>,
  },
  /**
   * * Фильтр
   */
  filter: {
    type: Object as PropType<MapFilterModel>,
  },
  /**
   * * Высота карты
   */
  height: {
    type: String,
    default: "300px",
  },
  /**
   * * Связывать точки линиями
   */
  isPolyline: {
    type: Boolean,
    default: true,
  },
  isSearchControl: { type: Boolean, default: true },
  /**
   * * Включить загрузку
   */
  isLoader: {
    type: Boolean,
  },
});

/**
 * События
 */
const emit = defineEmits<{
  (e: "added", value: MarkerMapModel): void;
  (e: "filter", value: MapFilterModel): void;
}>();
// ["added"]
/**
 * * Ширина маркера
 */
const iconWidth = ref(25);
/**
 * * Высота маркера
 */
const iconHeight = ref(40);
/**
 * * Ссылка на карту
 */
const lMapRef = ref();
/**
 * * Проинициализировалась карта
 */
const isInitMap = ref();
/**
 * * Настройки поиска
 */
const searchControl = ref();
/**
 * * Провайдер
 */
const provider = ref(new OpenStreetMapProvider());
/**
 * * Фильтр
 */
const filter = ref(new MapFilterModel(props.filter));

onMounted(() => {
  setTimeout(() => {
    if (!isInitMap.value) {
      init();
    }
  }, 500);
});
const mapGlobal = ref();
const c = ref(props.center);
watch(
  () => props.center,
  (x) => {
    console.log("dik", x);
    c.value = props.center;
  },
  { deep: true, immediate: true }
);
function onClickMarker(value: any) {
  console.log("click", value);
  if (value?.Count > 1) {
    filter.value.Zoom = filter.value.Zoom + 1;
    c.value = [value.lat, value.lng];
    event.preventDefault();
  }
}
/**
 * * Инициализация карты
 */
function init() {
  // Инициализация поиска
  searchControl.value = GeoSearchControl({
    provider: provider.value,
    showMarker: false,
    searchLabel: "Введите адрес",
    style: "bar",
    retainZoomLevel: true,
    keepResult: true,
  });

  if ((lMapRef.value as LMap)?.leafletObject?.addControl) {
    let map = (lMapRef.value as LMap).leafletObject;

    mapGlobal.value = map;
    if (props.isSearchControl) {
      map.addControl(searchControl.value);
      searchControl.value.map.on("geosearch/showlocation", (res: any) => {
        emit(
          "added",
          new MarkerMapModel({ lat: res.location.y, lng: res.location.x })
        );
      });
    }
    isInitMap.value = true;
  }

  const map = lMapRef.value;
}

const onClosePopUp = () => {
  mapGlobal.value.closePopup();
};
/**
 * * Добавить маркер
 */
const onClick = ({ latlng }: any) => {
  if (latlng) {
    emit("added", new MarkerMapModel({ lat: latlng.lat, lng: latlng.lng }));
  }
};

/**
 * * Создание маршрута между маркерами
 */
const polyline = computed(() =>
  props.markers ? props.markers.map((x) => [x.lat, x.lng]) : []
);

const onDragen = (value: any) => {
  const LatLng = value.target._latlng;

  if (LatLng) {
    emit("added", new MarkerMapModel({ lat: LatLng.lat, lng: LatLng.lng }));
  }
};

const updateBounds = (value: any) => {
  filter.value.NorthEast = new MarkerMapModel({
    lat: value._northEast.lat,
    lng: value._northEast.lng,
  });

  filter.value.SouthEast = new MarkerMapModel({
    lat: value._southWest.lat,
    lng: value._southWest.lng,
  });
  console.log("filter", filter.value);
  emit("filter", filter.value);
};

const updateZoom = (value: any) => {
  filter.value.Zoom = value;
  // emit("filter", filter.value);
};

const getClusterNumber = (point: any) => {
  return point?.Count ?? 0;
};

defineExpose({
  onClosePopUp,
});
</script>
<style>
:root {
  .leaflet-control-attribution.leaflet-control svg {
    display: none !important;
  }
}
</style>
<style lang="scss">
.ui-map {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;

  .pp {
    position: absolute;
    top: -45px;
    font-weight: bold;
    background: var(--main-color);
    border-radius: 8px;
    padding: 0px 3px;
    // min-width: 20px;
    text-align: center;
    // min-height: 20px;
    color: #fff;

    // border: 1px solid #5d58b7;
  }
}

.leaflet-popup-content-wrapper {
  background: transparent;
  box-shadow: var(--v-menu-dropdown-box-shadow);

  .leaflet-popup-content {
    width: auto !important;
    margin: 0;
  }
}

.leaflet-popup-close-button {
  display: none;
}

.leaflet-control-geosearch {
  position: relative;
  display: block;
  height: auto;
  width: 400px;
  max-width: calc(100% - 120px);
  margin: 10px auto 0;
  cursor: auto;
  z-index: 1000;
  background: #fff;
  color: #000;
  border-radius: 8px;

  > form {
    > input {
      width: 100%;
      font-size: 14px;
      border-radius: 8px;
      padding: 12px;
      padding-right: 30px;
      min-height: 48px;
      background-color: #fff;
      color: #000;

      &:focus {
        outline: none;
      }
    }

    > .reset {
      position: absolute;
      right: 10px;
      font-size: 25px;
      top: 5px;
      transition: all 0.15s ease-in;
      cursor: pointer;
      color: rgba(0, 0, 0, 0.6);

      &:hover {
        color: rgba(0, 0, 0, 0.9);
      }
    }

    > .results {
      &.active {
        border-top: solid 1px rgb(0, 0, 0, 0.1);
      }

      > div {
        padding: 5px 12px;
        color: rgba(0, 0, 0, 0.6);
        font-size: 13px;
        transition: all 0.15s ease-in;
        cursor: pointer;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;

        &:hover {
          background-color: rgba(0, 0, 0, 0.1);
        }
      }
    }
  }
}

.leaflet-div-icon {
  background: transparent;
  border: 1px solid transparent;
}

.leaflet-control-container {
  .geosearch {
    display: none;
  }
}

.l-map-point-img {
  position: absolute;
  bottom: 0;
  left: -10px;
}

.l-map-points-count {
  background-color: white;
  color: black;
  font-weight: 600;
  font-size: 14px;
  position: absolute;
  width: 22px;
  height: 22px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  bottom: 13px;
  left: -5px;
}
</style>
